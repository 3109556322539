<template>
<div class="">
  <!-- <div class="checkbox"  style="text-align:left; margin: 0px 0px 20px 0px;" v-if=" detail_info.year_info.end > detail_info.year_info.start">
      <span style="font-weight: bold;">{{ detail_info.year_info.current }}年 </span>
      <el-slider :format-tooltip="(value) => `${detail_info.year_info.certitude === 'guess' ? '约' :  detail_info.year_info.certitude === 'less_start' ? '猜' :  detail_info.year_info.certitude === 'less_end' &&  value - detail_info.year_info.start >= 50 ? '或' : ''}${value-detail_info.year_info.start}岁`"  v-model="detail_info.year_info.current" :min="detail_info.year_info.start" :max="detail_info.year_info.end" />
  </div> -->
  <!-- <OneBookParagraphSearch  v-for="(info, index) in list" :key="index" :paragraph_info="info"  style="margin: 20px 0px;"/> -->
  <div  v-for="(personListinfo, index) in detail_info.person_list" :key="index"  style="margin:30px 0px 0px 0px; text-align:left;">
    <div style="margin:10px 0px 10px 0px;">
      <el-tag class="ml-2" size="large">{{ personListinfo.name }}</el-tag>
      <span style="margin: 0px 6px; color: #ccc; vertical-align: middle; font-size: 13px;">{{ personListinfo.desc }}</span>
    </div>
    <div class="checkbox">
      <OnePersonYear  :person_info="person" :current_year="detail_info.year_info.current" tag="detail" @click="handleClick(person)" v-for="(person, index) in personListinfo.list"  :key="index" style="margin-bottom: 6px;" ></OnePersonYear>
    </div>
  </div>
</div>
</template>

<script>
// import Utils from '@/utils/utils'
// import axios from 'axios'
import OnePersonYear from '@/components/items/OnePersonYear'

export default {
  name: 'PersonListsView',
  props: {
    person_info: Map,
    callback: Function
  },
  components: {
    OnePersonYear
  },
  data () {
    return {
      loading: true,
      detail_info: this.person_info
    }
  },
  methods: {
    go_to (e) {
      const routeData = this.$router.resolve({ name: 'Person_detail', params: { id: e } })
      window.open(routeData.href, '_blank')
    },
    handleClick (e) {
      this.callback(e)
    }
  }
}
</script>

<style scoped>
</style>
