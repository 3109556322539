<template>
 <div class="checkbox">
    <div style="height:10px; background:#bbe6d6;margin:6px 0px;"></div>
    <div class="checkbox net_view">
      <el-checkbox-group @change="add_select_tags('my')" v-model="detail_info.group_my_selects"  >
        <el-checkbox size="small" v-for="(group, index) in detail_info.group_my_list" :key="index" :label="group.id" :checked="group.value">{{group.name}}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div style="height:10px; background:#bbe6d6;margin:6px 0px;"></div>
    <div class="checkbox net_view">
      <el-checkbox-group @change="add_select_tags('net')" v-model="detail_info.group_net_selects"  >
        <el-checkbox size="small" v-for="(group, index) in detail_info.group_net_list" :key="index" :label="group.id" :checked="group.value">{{group.name}}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div style="height:10px; background:#bbe6d6;margin:6px 0px;"></div>
    <div class="checkbox net_view">
      <el-checkbox-group @change="add_select_tags('minzu')" v-model="detail_info.group_minzu_selects"  >
        <el-checkbox size="small" v-for="(group, index) in detail_info.group_minzu_list" :key="index" :label="group.id" :checked="group.value">{{group.name}}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div style="height:10px; background:#bbe6d6;margin:6px 0px;"></div>
    <div class="checkbox net_view">
      <el-checkbox-group @change="add_select_tags('power')" v-model="detail_info.group_power_selects"  >
        <el-checkbox size="small" v-for="(group, index) in detail_info.group_power_list" :key="index" :label="group.id" :checked="group.value">{{group.name}}</el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils/utils'
import axios from 'axios'

export default {
  name: 'PersonCheckView',
  props: {
    person_info: Map
  },
  data () {
    return {
      loading: true,
      detail_info: this.person_info
    }
  },
  methods: {
    add_select_tags (tag) {
      var groups = []
      var info = this.detail_info
      if (tag === 'power') {
        groups = info.group_power_selects
      } else if (tag === 'net') {
        groups = info.group_net_selects
      } else if (tag === 'my') {
        groups = info.group_my_selects
      } else if (tag === 'minzu') {
        groups = info.group_minzu_selects
      }
      axios.post('/person_add_groups/', { id: this.detail_info.id, groups: groups, tag: tag })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
          }
        })
    }
  }
}
</script>

<style scoped>
</style>
