<template>
  <div :class="[content_tag]">
    <span class="key">{{ key_value[0] }}</span>
    <span class="value">{{ key_value[1] }}</span>
  </div>
</template>
<script>
// import store from '@/store'
// import router from '@/router/index.js'
// import Utils from '@/utils/utils'
// import axios from 'axios'

export default {
  name: 'OneKeyValue',
  props: {
    keyValue: Array,
    tag: String
  },
  data () {
    return {
      key_value: this.keyValue,
      content_tag: this.tag
    }
  }
}
</script>

<style>
.long  {
  margin: 5px 0px;
}
.short{
  display: inline-block;
  margin: 5px 5px 5px 0px;
}
.key{
  color: #ccc;
  display: inline-block;
  margin-right: 5px;
}
.valve{
  color: #000;
}
</style>
