<template>
  <div class="" style="text-align: left;">
    <div class="info_head" >
      <OnePersonLittle style="vertical-align: middle;" :person_info="detail_info"  @click="go_to(detail_info.id)"/>
      <!-- <span class="gray">{{detail_info.dynasty}}</span> -->
      <div style="display: inline-block; margin: 0px 5px; vertical-align: middle;">
        <span class="gray" v-for="(power, index) in detail_info.power_list"  :key="index" >{{power.word}}</span>
      </div>
      <div v-if="detail_info.year_info.certitude!=='less_all'"  style="display: inline-block; margin: 0px 5px; vertical-align: middle;">
        <span v-if="!detail_info.year_info.less_start" style="color:#67C23A;">{{detail_info.year_info.start}}</span>
        <span style="color:#ccc; display: inline-block; margin: 0px 2px;">~</span>
        <span v-if="!detail_info.year_info.less_end" style="color:#67C23A;">{{detail_info.year_info.end}}</span>
      </div>
      <el-button v-if="infoTag !== 'little'" type="info" plain size='small' style="width:42px;vertical-align: middle; margin: 0px 5px; " @click="search_word('book', detail_info.word)">搜史料</el-button>

      <!-- <el-button v-if="infoTag !== 'little'" type="info" plain size='small' style="width:42px;vertical-align: middle;" @click="search_word('baike', detail_info.word)">百科</el-button> -->
      <span v-if="infoTag === 'little'"  :disabled="disabled"  :style="'color: #ccc; float: right; font-size: 18px; cursor:pointer;'" @click="go_to(detail_info.id)">详 ></span>
      <span v-else :disabled="disabled"  :style="'color:' + (detail_info.mark ?  'orange' : '#ccc') + '; float: right; font-size: 25px; cursor:pointer;'" @click="mark_or_not" >{{ detail_info.mark ? '★' : '☆' }}</span>
      <el-icon><StarFilled /></el-icon>
    </div>
    <div style="margin: 10px 0px;"> {{detail_info.desc}}</div>

    <div v-if="detail_info.key_list">
      <div  v-for="(info , index) in detail_info.key_list"  :key="index" >
        <div v-if="info.list.length">
          <div v-if="info.tag === 'short'" class="short">
            <OneKeyValue  :keyValue="keyValue" tag="short" v-for="(keyValue, index) in info.list"  :key="index" />
          </div>
          <div v-else class="long">
            <OneKeyValue  :keyValue="keyValue" tag="long" v-for="(keyValue, index) in info.list"  :key="index" />
          </div>
        </div>
      </div>
    </div>

    <div style="height:10px; background:#bbe6d6;margin:6px 0px;"></div>
    <el-dialog v-model="show.login" title=""  class="login_view" width="30%"  center :show-close="false" @closed="close_view"><LoginDialog /></el-dialog>
  </div>
</template>

<script>
import Utils from '@/utils/utils'
import OnePersonLittle from '@/components/items/OnePersonLittle'
import OneKeyValue from '@/components/items/OneKeyValue'
import LoginDialog from '@/components/other/LoginDialog'
import axios from 'axios'

export default {
  name: 'PersonInfoView',
  props: {
    person_info: Map,
    page_self: Boolean,
    tag: String
  },
  components: {
    OnePersonLittle, OneKeyValue, LoginDialog
  },
  data () {
    return {
      loading: true,
      disabled: false,
      detail_info: this.person_info,
      pageSelf: this.page_self,
      infoTag: this.tag,
      show: {}
    }
  },
  watch: {
    person_info: {
      handler (newVal) {
        console.log(newVal.word)
        console.log(this.detail_info.word)
        if (newVal.id !== this.detail_info.id) {
          this.detail_info = newVal
        }
      },
      deep: true
    }
  },
  methods: {
    copy_word (e) {
      Utils.alert_success('已复制到剪切板')
      if (navigator.clipboard && navigator.clipboard !== undefined && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        navigator.clipboard.writeText(e)
      } else {
        // 创建text area
        const textArea = document.createElement('textarea')
        textArea.value = e
        // 使text area不在viewport，同时设置不可见
        document.body.appendChild(textArea)
        // textArea.focus()
        textArea.select()
        document.execCommand('copy')
        // textArea.hidden()
        document.body.removeChild(textArea)
      }
    },
    go_to (e) {
      if (this.pageSelf) {
        this.copy_word(this.detail_info.word)
        return
      }
      const routeData = this.$router.resolve({ name: 'Person_detail', params: { id: this.detail_info.id } })
      window.open(routeData.href, '_blank')
    },
    search_word (a, word) {
      if (a === 'baidu') {
        window.open('https://www.baidu.com/s?wd=' + word, '_blank')
      } else if (a === 'baike') {
        window.open('https://baike.baidu.com/item/' + word + '/' + this.detail_info.baike_id, '_blank')
      } else if (a === 'book') {
        var routeData = this.$router.resolve({ name: 'Book_index', query: { word: this.detail_info.word + ' ' + this.detail_info.rword } })
        window.open(routeData.href, '_blank')
      }
    },
    mark_or_not (e) {
      if (Utils.check_is_niming()) {
        this.show.login = true
        return
      }
      if (this.disabled) {
        return
      }
      this.disabled = true
      axios.post('/mark_person_or_not/', { id: this.detail_info.id })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.detail_info.mark = res.data.data.mark
          this.disabled = false
        })
    }
  }
}
</script>

<style scoped>
</style>
