<template>
  <el-skeleton v-if="loading" :rows="5" animated  style="width: 44%;margin: 0px 30px 30px 0px; text-align: left;display: inline-block; vertical-align: top;" />
  <div v-else style="width: 44%;margin: 0px 30px 30px 10px; text-align: left; vertical-align: top;display: inline-block">

    <PersonInfoEditView v-if="is_editing" :person_info="detail_info" />
    <PersonInfoView  v-else :person_info="detail_info"/>
    <PersonListsView :person_info="detail_info" :callback="personLists_Callback"/>
    <PersonCheckView v-if="is_editing" :person_info="detail_info" />
  </div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import PersonInfoView from '@/components/divs/PersonInfoView'
import PersonListsView from '@/components/divs/PersonListsView'
import PersonInfoEditView from '@/components/divs/PersonInfoEditView'
import PersonCheckView from '@/components/divs/PersonCheckView'

import { isEditing } from '@/components/config.js'

export default {
  name: 'AnotherPersonView',
  props: {
    another_id: String,
    main_person_id: String
  },
  components: {
    PersonInfoView, PersonCheckView, PersonListsView, PersonInfoEditView
  },
  data () {
    return {
      loading: true,
      person_id: this.another_id,
      person_id0: this.main_person_id,
      base_img: store.state.base_img,
      base_url: store.state.base_url,
      show: false,
      detail_info: {},
      is_editing: isEditing
    }
  },
  watch: {
    another_id: function (e) {
      this.person_id = e
      this.get_person_detail(this.person_id)
    }
  },
  mounted () {
    this.get_person_detail(this.person_id)
  },
  methods: {
    get_person_detail (e) {
      this.loading = true
      axios.post(Utils.check_is_niming() ? '/person_detail_unlogin/' : '/person_detail/', { id: e, id0: this.person_id0 })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.detail_info = res.data.data.info
          console.log(this.detail_info)
          this.loading = false
        })
    },
    personLists_Callback (e) {
      this.get_person_detail(e.id)
    }
  }
}
</script>

<style>
.group_view .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color:#50b7c1;border-color:#50b7c1;}
.group_view .el-checkbox__input.is-checked + .el-checkbox__label{color:#50b7c1;}
.group_view .el-checkbox.is-bordered.is-checked{border-color:#50b7c1;}
.group_view .el-checkbox__input.is-focus .el-checkbox__inner{border-color: #50b7c1;}
.net_view .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color:#de8af8;border-color:#de8af8;}
.net_view .el-checkbox__input.is-checked + .el-checkbox__label{color:#de8af8;}
.net_view .el-checkbox.is-bordered.is-checked{border-color:#de8af8;}
.net_view .el-checkbox__input.is-focus .el-checkbox__inner{border-color: #de8af8;}
.img_window_big .bytes{display: inline-block; margin: 3px 8px;}

</style>
