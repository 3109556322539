<template>
<div class="edit_view" style="text-align: left;">
  <div style="margin:0px 0px">
    <div style="display:inline-block;">
      <div style="display: inline-block;margin: 5px;">
        <OnePersonLittle v-if="detail_info.word" :person_info="detail_info" :fix_size="true" @click="copy_word(detail_info.word)"></OnePersonLittle>
        <el-button  type="warning" class="mx-1"  plain  @click="want_add_x('word')" size="small" >改</el-button>
      </div>
      <span class="gray">{{detail_info.dynasty}}</span>
      <div style="display: inline-block;margin: 5px;">
        <text>index</text>
        <text style="color:#67C23A;">{{detail_info.index}}</text>
        <el-button  type="warning" class="mx-1"  plain  @click="want_add_x('index')" size="small" >改</el-button>
      </div>
      <div style="display: inline-block;margin: 5px;">
        <text>num</text>
        <text style="color:#67C23A;">{{detail_info.num_all}}</text>
        <el-button  type="info" class="mx-1"  plain  @click="baike_edit_percent('detail', 'num_all')" size="small" >1/10</el-button>
        <el-button  type="warning" class="mx-1"  plain  @click="want_add_x('num_all')" size="small" >改</el-button>
      </div>

      <el-button type="primary" plain size='small' style="width:42px" @click="search_word('baidu', detail_info.word)">百度</el-button>
      <el-button type="primary" plain size='small' style="width:42px" @click="search_word('baike', detail_info.word)">百科</el-button>
    </div>
    <div v-if="show_x['num_all']" style="margin:20px 0px">
      <div style="margin: 10px 0px;"><el-input v-model="detail_info.num_all" class="w-50 m-2" size="large" placeholder="num" /> </div>
      <div class=""><el-button type="primary" @click="edit_x('num_all', detail_info.num_all)" size='large'>保存</el-button></div>
    </div>
    <div v-if="show_x['index']" style="margin:20px 0px">
      <div style="margin: 10px 0px;"><el-input v-model="detail_info.index" class="w-50 m-2" size="large" placeholder="index" /> </div>
      <div class=""><el-button type="primary" @click="edit_x('index', detail_info.index)" size='large'>保存</el-button></div>
    </div>
    <div v-if="show_x['word']" style="margin:20px 0px">
      <div style="margin: 10px 0px;"><el-input v-model="detail_info.word" :autosize="{ minRows: 2, maxRows: 5 }" type="textarea" placeholder="word" /> </div>
      <div class=""><el-button type="primary" @click="edit_x('word', detail_info.word)" size='large'>保存</el-button></div>
    </div>
  </div>
  <div>
    <div style="display: inline-block;margin: 5px;">
      <text>year</text>
      <text style="color:#67C23A;">{{detail_info.year_index}}</text>
      <el-button  type="warning" class="mx-1"  plain  @click="want_add_x('year_index')" size="small" >改</el-button>
    </div>
    <div style="display: inline-block;margin: 5px;">
      <text>出生</text>
      <text style="color:#67C23A;">{{detail_info.year_birth}}</text>
      <el-button  type="warning" class="mx-1"  plain  @click="want_add_x('year_birth')" size="small" >改</el-button>
    </div>
    <div style="display: inline-block;margin: 5px;">
      <text>逝世</text>
      <text style="color:#67C23A;">{{detail_info.year_death}}</text>
      <el-button  type="warning" class="mx-1"  plain  @click="want_add_x('year_death')" size="small" >改</el-button>
    </div>
    <div v-if="show_x['year_index']" style="margin:20px 0px">
      <div style="margin: 10px 0px;"><el-input v-model="detail_info.year_index" :autosize="{ minRows: 2, maxRows: 1 }" type="textarea" placeholder="year_index" /> </div>
      <div class=""><el-button type="primary" @click="edit_x('year_index', detail_info.year_index)" size='large'>保存</el-button></div>
    </div>
    <div v-if="show_x['year_birth']" style="margin:20px 0px">
      <div style="margin: 10px 0px;"><el-input v-model="detail_info.year_birth" :autosize="{ minRows: 1, maxRows: 1 }" type="textarea" placeholder="year_birth" /> </div>
      <div class=""><el-button type="primary" @click="edit_x('year_birth', detail_info.year_birth)" size='large'>保存</el-button></div>
    </div>
    <div v-if="show_x['year_death']" style="margin:20px 0px">
      <div style="margin: 10px 0px;"><el-input v-model="detail_info.year_death" :autosize="{ minRows: 1, maxRows: 1 }" type="textarea" placeholder="year_death" /> </div>
      <div class=""><el-button type="primary" @click="edit_x('year_death', detail_info.year_death)" size='large'>保存</el-button></div>
    </div>
  </div>
  <div class="checkbox"  style="text-align:left;">
    <div>
      <text>{{detail_info.rword_new}} ----- {{detail_info.rword}}</text>
      <el-button  type="warning" class="mx-1"  plain  @click="want_add_x('rword')" size="small" >改</el-button>
    </div>
    <div>
      <text>{{detail_info.desc}}</text>
      <el-button  type="warning" class="mx-1"  plain  @click="want_add_x('desc')" size="small" >改</el-button>
    </div>

    <div v-if="show_x['rword']" style="margin:20px 0px">
      <div style="margin: 10px 0px;"><el-input v-model="detail_info.rword" :autosize="{ minRows: 2, maxRows: 5 }" type="textarea" placeholder="别名" /> </div>
      <div class=""><el-button type="primary" @click="edit_x('rword', detail_info.rword)" size='large'>保存</el-button></div>
    </div>
    <div>
      <div v-if="show_x['desc']" style="margin:20px 0px">
        <div style="margin: 10px 0px;"><el-input v-model="detail_info.desc" :autosize="{ minRows: 2, maxRows: 5 }" type="textarea" placeholder="添加简短描述" /> </div>
        <div class=""><el-button type="primary" @click="edit_x('desc', detail_info.desc)" size='large'>保存</el-button></div>
      </div>
    </div>
  </div>

  <div style="height:10px; background:#bbe6d6;margin:6px 0px;"></div>
  <div>{{detail_info.content}}</div>
  <div style="height:10px; background:#bbe6d6;margin:6px 0px;"></div>
</div>
</template>

<script>
import Utils from '@/utils/utils'
import axios from 'axios'
import OnePersonLittle from '@/components/items/OnePersonLittle'

export default {
  name: 'PersonInfoEditView',
  props: {
    person_info: Map
  },
  components: {
    OnePersonLittle
  },
  data () {
    return {
      loading: true,
      detail_info: this.person_info,
      show_x: {}
    }
  },
  methods: {
    copy_word (e) {
      Utils.alert_success('已复制到剪切板')
      if (navigator.clipboard && navigator.clipboard !== undefined && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        navigator.clipboard.writeText(e)
      } else {
        // 创建text area
        const textArea = document.createElement('textarea')
        textArea.value = e
        // 使text area不在viewport，同时设置不可见
        document.body.appendChild(textArea)
        // textArea.focus()
        textArea.select()
        document.execCommand('copy')
        // textArea.hidden()
        document.body.removeChild(textArea)
      }
    },
    go_to (e) {
      const routeData = this.$router.resolve({ name: 'Person_detail', params: { id: e } })
      window.open(routeData.href, '_blank')
    },
    search_word (a, word) {
      if (a === 'baidu') {
        window.open('https://www.baidu.com/s?wd=' + word, '_blank')
      } else if (a === 'baike') {
        window.open('https://baike.baidu.com/item/' + word + '/' + this.detail_info.baike_id, '_blank')
      }
    },
    want_add_x (tag) {
      if (this.show_x[tag]) {
        this.show_x = {}
      } else {
        this.show_x = {}
        this.show_x[tag] = true
      }
    },
    edit_x (tag, e) {
      axios.post('/baike_edit_x/', { id: this.detail_info.id, tag: tag, content: e })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.show_x = {}
          this.detail_info = res.data.data.info
        })
    },
    baike_edit_percent (whichInfo, tag) {
      this.show_x = {}
      axios.post('/baike_edit_percent/', { id: this.detail_info.id, tag: tag })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.detail_info = res.data.data.info
        })
    }
  }
}
</script>

<style scoped>
</style>
