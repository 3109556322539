<template>
  <div :class="['name_little']" >
    <div v-if="info.year_info.certitude === 'less_all'" :class="['name_little_' + info.div_size, 'perosn_year_none', 'perosn_year_none_' + info.gender, info.word.length>3 ? 'name_little_long' : 'name_little_short']">
      <div class="name_word">
        <span :class="['name_little_len_' + info.word.length]" v-for="(zi, index) in info.word"  :key="index" >{{ zi }}</span>
      </div>
    </div>
    <div v-else-if="info.year_info.less_start && info.year_info.less_end" :class="['name_little_' + info.div_size, 'perosn_year_none', info.word.length>3 ? 'name_little_long' : 'name_little_short']">
      <div class="name_word">
        <span :class="['name_little_len_' + info.word.length]" v-for="(zi, index) in info.word"  :key="index" >{{ zi }}</span>
      </div>
    </div>
    <div v-else-if="year_index < info.year_info.start" :class="['name_little_' + info.div_size, info.word.length>3 ? 'name_little_long' : 'name_little_short', 'perosn_breed']">
      <span :class="['name_little_len_' + info.word.length]" v-for="(zi, index) in info.word"  :key="index" >{{ zi }}</span>
    </div>
    <div v-else-if="year_index > info.year_info.end"  :class="['name_little_' + info.div_size, 'perosn_die', info.word.length>3 ? 'name_little_long' : 'name_little_short']">
      <span :class="['name_little_len_' + info.word.length]" v-for="(zi, index) in info.word"  :key="index" >{{ zi }}</span>
    </div>
    <div v-else-if="year_index === info.year_info.start && year_index !== 9999 " class="perosn_breeding" >
      <div :class="['name_little_' + info.div_size, info.word.length>3 ? 'name_little_long' : 'name_little_short', 'breeding_gender_' + info.gender, info.gender == 0 ? '' : 'breeding_minzu_' + info.tag_minzu, info.gender == 0 &&  info.tag_minzu ==2 ? 'border_nv_minzu' : '']"  >
        <span :class="['name_little_len_' + info.word.length]" v-for="(zi, index) in info.word"  :key="index" >{{ zi }}</span>
      </div>
    </div>
    <div v-else-if="year_index === info.year_info.end && year_index !== 9999 " class="person_dying" >
      <div :class="['name_little_' + info.div_size, info.word.length>3 ? 'name_little_long' : 'name_little_short', 'dying_gender_' + info.gender, info.gender == 0 ? '' : 'dying_minzu_' + info.tag_minzu, info.gender == 0 &&  info.tag_minzu ==2 ? 'border_nv_minzu' : '']"  >
        <span :class="['name_little_len_' + info.word.length]" v-for="(zi, index) in info.word"  :key="index" >{{ zi }}</span>
        <div class="year">
          <span class="year_tag">{{ info.year_info.certitude === 'infer' ? '约' :  info.year_info.certitude === 'less_start' ? '猜' :  info.year_info.certitude === 'less_end' &&  year_index - info.year_info.start >= 50 ? '或' : ''}}</span>
          <span class="year_num">{{year_index - info.year_info.start}}</span>
        </div>
      </div>
    </div>
    <div v-else :class="['name_little_' + info.div_size, info.word.length>3 ? 'name_little_long' : 'name_little_short', 'gender_' + info.gender, info.gender == 0 ? '' : 'minzu_' + info.tag_minzu, info.gender == 0 &&  info.tag_minzu ==2 ? 'border_nv_minzu' : '', info.year_info.end === year_index && year_index !== 9999 ? 'perosn_dying' : '',]"  >
      <span :class="['name_little_len_' + info.word.length]" v-for="(zi, index) in info.word"  :key="index" >{{ zi }}</span>
      <div class="year">
        <span class="year_tag">{{ info.year_info.certitude === 'infer' ? '约' :  info.year_info.certitude === 'less_start' ? '猜' :  info.year_info.certitude === 'less_end' &&  year_index - info.year_info.start >= 50 ? '或' : ''}}</span>
        <span class="year_num">{{year_index - info.year_info.start}}</span>
      </div>
    </div>
    <router-link  target="_blank" :to="{ name: 'Person_detail', params: { id: info.id }}"><span  style="display: inline-block ;width:1px;height:1px; overflow: hidden;" >{{ info.word }} - 历史年轮 同时代人物，年龄动态变化</span></router-link>
  </div>
</template>
<script>
// import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'

export default {
  name: 'OnePersonYear',
  props: {
    person_info: Map,
    current_year: String
  },
  watch: {
    current_year () {
      this.year_index = this.current_year
    }
  },
  data () {
    return {
      info: this.person_info,
      year_index: this.current_year
    }
  },
  methods: {
    add_select_tags (whichInfo, tag) {
      console.log(tag)
      var groups = []
      var baikeId = this.info.id
      var info = this.info
      if (whichInfo === 'another') {
        baikeId = this.another_info.id
        info = this.another_info
      } else if (whichInfo === 'detail') {
        info = this.detail_info
      }

      if (tag === 'power') {
        groups = info.group_power_selects
      } else if (tag === 'net') {
        groups = info.group_net_selects
      } else if (tag === 'my') {
        groups = info.group_my_selects
      } else if (tag === 'minzu') {
        groups = info.group_minzu_selects
      }
      axios.post('/person_add_groups/', { id: baikeId, groups: groups, tag: tag })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
          }
        })
    }
  }
}
</script>

<style scoped>
.name_little{
  cursor:pointer;
  display: inline-block;
  color: #fff;
  border-radius: 8px;
  /* background: #50b7c1; */
  margin:0px 6px 0px 0px;
  vertical-align: top;
  text-align: center;
}
.color_w1{
  color: #ccc;
}
.font30{
  font-size: 13px;
}
.name_little .gender_0{
  background: pink;
}

.name_little .gender_1{
  background: #50b7c1;
}
.name_little .gender_2{
  background: #50b7c1;
}
.name_little .minzu_2{
  background: #de8af8;
}

.name_little_large{
  display: inline-block;
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  /* padding: 8px 15px 8px 18px; */
}
.name_little_default{
  display: inline-block;
  font-size: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
  /* padding: 7px 12px 7px 14px; */
}
.name_little_small{
  display: inline-block;
  font-size: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
  /* padding: 7px 8px 7px 10px; */
}
.name_tags{
  vertical-align: top;
  display: inline-block;
  width: 20px;
  margin: 0px 0px 0px 5px;

  /* min-height: 50px;

  /* display: flex; */
}
.name_tag{
  margin: 0px 5px 0px 0px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 6px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 10px;

}
.color_pink{
  color: pink;
  border: 1px solid pink;

}
.color_blue{
  color: rgb(21, 101, 158);
  border: 1px solid rgb(21, 101, 158);
}
.color_lightblue{
  color: lightblue;
  border: 1px solid lightblue;

}
.color_green{
  color: rgb(203, 167, 37);
  border: 1px solid rgb(203, 167, 37);

}
.color_yellow{
  color: rgb(190, 206, 23);
  border: 1px solid rgb(190, 206, 23);

}
.color_orange{
  color: orange;
  border: 1px solid orange;

}
.border_nv_minzu{
  /* border: 2px solid #de8af8; */
  color: #de8af8;
}

.perosn_breed{
  color: #888;
  box-shadow: 0px 0px 2px rgb(153, 153, 153,0.4) inset;
}
.person_dying{
  background: linear-gradient(to right, #50b7c1, #bcbcbc);
  display: inline-block;
}

.dying_gender_0 {
  background: linear-gradient(to right, pink, #bcbcbc);
  display: inline-block;
}
.dying_minzu_2 {
  background: linear-gradient(to right, #de8af8, #bcbcbc);
  display: inline-block;
}
.perosn_breeding{
  background: linear-gradient(to right,rgba(80, 183, 193, 0.5), #50b7c1);
  display: inline-block;
}
.breeding_gender_0 {
  background: linear-gradient(to right,rgb(249, 240, 245), pink);
  display: inline-block;
}
.breeding_minzu_2 {
  background: linear-gradient(to right,#f0d9f7, #de8af8);
  display: inline-block;
}

.perosn_die{
  background-color: #bcbcbc;
}
.name_word,.year{
  display: inline-block;
  vertical-align: middle;
}
.year{
  padding-left: 3px;
}
.year span{
  display: block;
  font-size:12px;
  text-align: center;
  line-height: 12px;
  -webkit-transform:scale(0.9);

}
.year span.year_tag{
  -webkit-transform:scale(0.8);
}
.name_little_len_2{
  padding: 0px 4px;
  display: inline-block;
}
.name_little_short{
  padding-right: 10px;
  padding-left: 10px;
}
.name_little_long{
  padding-right: 5px;
  padding-left: 5px;
}
.perosn_year_none{
  box-shadow: 0px 0px 2px rgb(153, 153, 153,0.4) inset;
}
.perosn_year_none_0{
  color: pink;
}
.perosn_year_none_1{
  color: #50b7c1;
}
.perosn_year_none_2{
  color: #50b7c1;
}
</style>
